
















import {Vue,Component, Prop} from 'vue-property-decorator';
@Component({
    name : 'MesaCard'
})
export default class MesaCard extends Vue 
{
 
  @Prop({type:String,required:true}) titulo!:string;
  @Prop({type:String,required:true}) cantidad!:string;
  @Prop({type:String,required:true}) volumen!:string;
    
}
